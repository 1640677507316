.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #e6e6fa;
}

.Main-header {
  font-family: Garamond;
  background-color: #698b69;
  text-align: center;
  color: #8b5a2b;
}
.Home {
  text-align: left;
}
.Wedding-Home {
  color: #8b7b8b;
  overflow: scroll;
}
.Ceremony-Column {
  text-align: justify;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
